import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import BITLogo from '../data/img/bitLogo.png';
import QRCode from '../data/img/bitQR.png';

const BitcoinExchangeComponent = () => {
    const [usdtToIls, setUsdtToIls] = useState(null);
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');
    const [protocol, setProtocol] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Bit');
    const [order, setOrder] = useState('Buy');
    const [isQRModalOpen, setQRModalOpen] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=ils');
                const data = await response.json();
                setUsdtToIls(data.tether.ils);
            } catch (error) {
                console.error("Failed to fetch USDT to ILS rate:", error);
            }
        };

        fetchExchangeRate();
        const interval = setInterval(fetchExchangeRate, 60000); // Updates every minute

        return () => clearInterval(interval);
    }, []);

   // Function to open the IndexedDB and save/retrieve transaction ID
const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('TransactionDB', 1);

        request.onerror = () => {
            console.error('Database failed to open');
            reject();
        };

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onupgradeneeded = (e) => {
            const db = e.target.result;
            db.createObjectStore('transactions', { keyPath: 'id' });
        };
    });
};

const saveTransactionId = async (id) => {
    const db = await openDatabase();
    const transaction = db.transaction('transactions', 'readwrite');
    const store = transaction.objectStore('transactions');
    store.put({ id: 'transactionId', value: id });
};

const getTransactionId = async () => {
    const db = await openDatabase();
    return new Promise((resolve) => {
        const transaction = db.transaction('transactions', 'readonly');
        const store = transaction.objectStore('transactions');
        const request = store.get('transactionId');

        request.onsuccess = () => {
            resolve(request.result ? request.result.value : 1);
        };
    });
};

// Modify handleTelegramMessage to use IndexedDB
const handleTelegramMessage = async () => {
    const adjustedRate = order === 'Sell' ? usdtToIls * 0.99 : usdtToIls * 1.01;
    const transactionId = await getTransactionId(); // Get current transaction ID
    const totalInShekels = usdtToIls ? (amount * adjustedRate).toFixed(2) : 'Calculating...';

    const message = `
        Transaction ID: ${transactionId}
        Amount (USDT): ${amount}
        Address: ${address}
        Transfer Protocol: ${protocol}
        Payment Method: ${paymentMethod}
        Total in Shekels: ${formatPrice(totalInShekels)}
        Gate: ${formatPrice(adjustedRate)}
        **${order}** 
    `;

    // Encode the message
    const encodedMessage = encodeURIComponent(message);

    // Define the Telegram links
    const links = [
        `https://t.me/drbrokers?text=${encodedMessage}`,
    ];
    // `https://t.me/dandan900900?text=${encodedMessage}`
    // Randomly select one link
    const randomLink = links[Math.floor(Math.random() * links.length)];

    // Open the randomly selected link in a new tab
    window.open(randomLink, '_blank');

    // Increment transaction ID and save it for next use
    await saveTransactionId(transactionId + 1);
};

// Handle amount input, allowing only numbers
const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
        setAmount(value);
        setError('');  // Clear any error when valid
    } else {
        setError('Amount must be a number.');
    }
};

// Function to validate address based on selected protocol
const isAddressCompatible = (address, protocol) => {
    // Check for ERC20 and BEP20 compatibility (42-character hex format starting with '0x')
    const isHexAddress = /^0x[a-fA-F0-9]{40}$/.test(address);
    // Check for TRC20 compatibility (TRON addresses start with 'T' and are 34 characters long)
    const isTronAddress = /^T[a-zA-Z0-9]{33}$/.test(address);

    if ((protocol === 'ERC20' || protocol === 'BEP20') && isHexAddress) return true;
    if (protocol === 'TRC20' && isTronAddress) return true;

    return false;
};


// Handle address validation based on protocol
const handleAddressChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    
    // Check if address is compatible with selected protocol
    if (protocol && !isAddressCompatible(value, protocol)) {
        setError(`Address does not match the selected protocol (${protocol}).`);
    } else {
        setError(''); // Clear error if address is valid
    }
};

// Handle protocol selection and validate address
const handleProtocolChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value === 'ERC20' || value === 'BEP20' || value === 'TRC20') {
        setProtocol(value);
        
        // Check if the current address is compatible with the new protocol
        if (address && !isAddressCompatible(address, value)) {
            setError(`Address does not match the selected protocol (${value}).`);
        } else {
            setError(''); // Clear error if address is valid
        }
    } else {
        setError('Protocol must be either ERC20 or BEP20.');
    }
};


// Format price with commas and currency symbol
const formatPrice = (price) => {
    return price ? `${price.toLocaleString('en-US')}\u20AA` : 'Loading...';
};

    const toggleQRModal = () => {
        setQRModalOpen(!isQRModalOpen);
    };

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial', textAlign: 'center' }}>
            <div className="section-title">
            <h2>ILS Exchange: Buy & Sell Usdt</h2>
            <p>1 USDT = {formatPrice(usdtToIls)} ILS *</p>
            <p style={{ fontSize: 'small', color: 'gray' }}>*Rates update every minute</p>
            <h3>Conversion Explanation</h3>
            {/* Telegram Transaction Guide */}
            <ol style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>
                <li>1.Enter the desired amount in USDT, recipient address, and transfer protocol in the fields provided.</li>
                <li>2.Select your payment method: choose between <strong>Bit</strong> or <strong>Cash</strong>.</li>
                <li>If you selected <strong>Bit</strong> as your payment method, Scan the QR or Click Bit app logo, please remember to add the transaction ID to the description field in the Bit app. 
                This transaction ID helps us track your payment and ensures a smooth transaction process.</li>
                <li>3.Click the Telegram icon button below to initiate the transaction.</li>
                <li>A pre-filled message will open in Telegram, containing all the transaction details you entered.</li>
                <li>4.Review the details carefully to ensure accuracy.</li>
                <li>5.Send the message to confirm your transaction request. <strong>A confirmation will be sent back to you before the transaction is processed.</strong></li>
                <li><strong>Note: The correctness of the details is your responsibility.</strong></li>
            </ol>
</div>
<h3>Conversion Details:</h3>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                <label style={{ textAlign: 'center', fontWeight: 'bold' }}>Amount (USDT):</label>
                <input
                    type="text"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter amount in USDT"
                    style={{ width: '80%', maxWidth: '300px', padding: '8px', textAlign: 'center' }}
                />

<label style={{ textAlign: 'center', fontWeight: 'bold' }}>Address:</label>
<input
    type="text"
    value={address}
    onChange={handleAddressChange}
    placeholder="Enter recipient address"
    style={{ width: '80%', maxWidth: '300px', padding: '8px', textAlign: 'center' }}
    disabled={order === 'Sell'} // Disable when 'sell' is selected
/>
{order === 'Sell' && <p style={{ color: 'gray', fontSize: '12px' }}>Address input is disabled for sell orders.</p>}

<label style={{ textAlign: 'center', fontWeight: 'bold' }}>Transfer Protocol:</label>
<select
    value={protocol}
    onChange={handleProtocolChange}
    style={{ width: '80%', maxWidth: '300px', padding: '8px', textAlign: 'center' }}
>
    <option value="">Select protocol</option>
    <option value="ERC20">ERC20</option>
    <option value="BEP20">BEP20</option>
    <option value="TRC20">TRC20</option>
</select>


                {/* Display validation error if any */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {/* Payment Method Section */}
                <div style={{ marginTop: '15px', textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>Payment Method:</label>
                    <div>
                        <label style={{ cursor: 'pointer' }}>
                            <input
                                type="radio"
                                value="Bit"
                                checked={paymentMethod === 'Bit'}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            Bit
                        </label>
                        <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                            <input
                                type="radio"
                                value="Cash"
                                checked={paymentMethod === 'Cash'}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            Cash
                        </label>
                        <div>
                            <label style={{ cursor: 'pointer' }}>
                                <input
                                    type="radio"
                                    value="Buy"
                                    checked={order === 'Buy'}
                                    onChange={(e) => setOrder(e.target.value)}
                                    style={{ marginRight: '5px' }}
                                />
                                Buy
                            </label>
                            <label style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                <input
                                    type="radio"
                                    value="Sell"
                                    checked={order === 'Sell'}
                                    onChange={(e) => setOrder(e.target.value)}
                                    style={{ marginRight: '5px' }}
                                />
                                Sell
                            </label>
                    </div>
                    </div>   
                </div>

                <button onClick={handleTelegramMessage} style={{
                    backgroundColor: '#0088cc',
                    color: 'white',
                    padding: '10px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    marginTop: '15px'
                }}>
                    <FontAwesomeIcon icon={faTelegramPlane} style={{ fontSize: '20px' }} />
                </button>
            {/* "Pay with Bit" section */}
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                 <a href="https://bitpay.poalimlinks.co.il/app/me/FE913E73-1728-207B-BF46-769B5B5B81151889" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={BITLogo} alt="BIT Logo" style={{ width: '50px', height: '50px', verticalAlign: 'middle', cursor: 'pointer' }} />
                  <span style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '10px' }}>Pay with Bit</span>
                 </a>
                </div>

                <h3>Scan the QR in Bit</h3>
                {/* QR Code section with click-to-enlarge feature */}
                <div style={{ marginTop: '15px' }}>
                    <img
                        src={QRCode}
                        alt="QR Code"
                        style={{ width: '150px', height: '150px', cursor: 'pointer',borderRadius:15}}
                        onClick={toggleQRModal} // Opens modal on click
                    />
                </div>
            </div>

            <p style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>
                Note: The correctness of the details is the customer's responsibility. A confirmation will be sent before executing the transaction.
            </p>

            {/* Modal for enlarged QR Code */}
            {isQRModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <div style={{ position: 'relative' }}>
                        <img src={QRCode} alt="QR Code Large" style={{ width: '300px', height: '300px' }} />
                        <button onClick={toggleQRModal} style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            background: '#ccc',
                            border: 'none',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            fontSize: '20px',
                            cursor: 'pointer',
                        }}>✕</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BitcoinExchangeComponent;
