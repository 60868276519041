import React, { useState, useEffect, useRef } from 'react';
import jsQR from 'jsqr';

const QrScanner = () => {
  const [scannedUrl, setScannedUrl] = useState('');
  const [qrResult, setQrResult] = useState('');
  const [facingMode, setFacingMode] = useState('user');
  const [cameraOn, setCameraOn] = useState(false); // State to manage camera power
  const videoRef = useRef(null);

  /**
   * Handles successful QR code scans
   * Opens the scanned URL in a new tab
   */
  const handleScan = (data) => {
    if (data) {
      setScannedUrl(data);
      window.open(data, '_blank');
    }
  };

  /**
   * Handles errors from camera or scanning operations
   */
  const handleError = (error) => {
    console.error(error);
  };

  /**
   * Handles image uploads for QR code scanning
   */
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0, img.width, img.height);

          const imageData = context.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            setQrResult(code.data);
            window.open(code.data, '_blank');
          } else {
            alert('No QR code found in the image.');
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  /**
   * Toggles between the front-facing and rear-facing camera
   */
  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  /**
   * Toggles the camera on or off
   */
  const toggleCameraOn = () => {
    setCameraOn((prevState) => !prevState);
  };

  /**
   * Manages the camera feed and QR scanning loop
   */
  useEffect(() => {
    if (!cameraOn) {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
      return;
    }

    const constraints = {
      video: { facingMode: facingMode },
    };

    const currentVideoRef = videoRef.current;

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        if (currentVideoRef) {
          currentVideoRef.srcObject = stream;
        }

        const scanQRCode = () => {
          if (currentVideoRef) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = currentVideoRef.videoWidth;
            canvas.height = currentVideoRef.videoHeight;
            context.drawImage(currentVideoRef, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code) {
              handleScan(code.data);
            }
          }
          if (cameraOn) {
            requestAnimationFrame(scanQRCode);
          }
        };

        scanQRCode();
      })
      .catch((error) => {
        handleError(error);
      });

    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        currentVideoRef.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [facingMode, cameraOn]);

  return (
    <div className="qr-scanner-container" style={{ textAlign: 'center', position: 'relative' }}>
      <h1>QR Scanner</h1>
      <a 
        href="https://dr-brokers.com" 
        style={{
          display: 'block',
          marginBottom: '20px',
          color: '#007bff',
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >
        Return to Home
      </a>

      {/* Upload section for image-based QR scanning */}
      <div className="upload-section" style={{ marginTop: '20px' }}>
        <p>How to Use:</p>
        <ul>
          <li>Click the "Upload Image with QR Code" button.</li>
          <li>Choose an image file containing a QR code.</li>
          <li>If a QR code is detected, the URL will be displayed, and you can click it to open the link in a new tab.</li>
        </ul>
        <h3>Upload Image with QR Code</h3>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {qrResult && (
          <p>Scanned QR Code URL: <a href={qrResult} target="_blank" rel="noopener noreferrer">{qrResult}</a></p>
        )}
      </div>

      {/* Button to toggle camera on/off */}
      <div style={{ marginTop: '20px' }}>
        <h2>Scan QR code using device's camera.</h2>
        <p>How to Use:</p>
        <ul>
          <li>Click the "Turn Camera On" button to activate the camera and scan the QR.</li>
          <li>If the QR code is detected, it will automatically extract the URL and open it in a new tab.</li>
          <li>Toggle Camera View: Use the "Switch Camera" button to toggle between the front-facing and rear-facing cameras.</li>
        </ul>
        <button 
          onClick={toggleCameraOn} 
          style={{
            marginBottom: '10px',
            padding: '10px 20px',
            backgroundColor: cameraOn ? '#dc3545' : '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '25px',
            cursor: 'pointer',
            transition: 'background-color 0.3s, transform 0.3s',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = cameraOn ? '#b02a37' : '#0056b3'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = cameraOn ? '#dc3545' : '#007bff'}
        >
          {cameraOn ? 'Turn Camera Off' : 'Turn Camera On'}
        </button>

        {/* Button to switch between cameras */}
        {cameraOn && (
          <button 
            onClick={toggleCamera} 
            style={{
              marginBottom: '10px',
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '25px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, transform 0.3s',
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
          >
            Switch Camera
          </button>
        )}
      </div>

      {/* Video feed for real-time QR scanning */}
      {cameraOn && (
        <div className="qr-reader-section" style={{ position: 'relative', width: '100%', maxWidth: '300px', height: '300px', borderRadius: '15px', overflow: 'hidden' }}>
          <video
            ref={videoRef}
            id="video"
            autoPlay
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              transform: facingMode === 'user' ? 'scaleX(-1)' : 'scaleX(1)', // Mirror if front camera
            }}
          />
          {scannedUrl && (
            <p>Scanned URL: <a href={scannedUrl} target="_blank" rel="noopener noreferrer">{scannedUrl}</a></p>
          )}
        </div>
      )}
    </div>
  );
};

export default QrScanner;